import { ExternalLink } from 'react-external-link';
import { Link } from 'react-router-dom';

import './scss/Footer.scss';

import mcdonaldslogo from './assets/mcdonalds-logo.svg';
import facebook from './assets/facebook.svg';
import instagram from './assets/instagram.svg';
import twitter from './assets/twitter.svg';
import youtube from './assets/youtube.svg';

function Footer () {
    return (
        <footer>
            <div className='container'>
                <div className='social-icons'>
                    <ExternalLink href="https://www.facebook.com/McDonaldsUK/ ">
                        <img src={facebook} alt="Facebook Icon" />
                    </ExternalLink>
                    <ExternalLink href="https://twitter.com/mcdonaldsuk">
                        <img src={twitter} alt="Twitter Icon" />
                    </ExternalLink>
                    <ExternalLink href="https://www.youtube.com/user/mcdonaldsuk">
                        <img src={youtube} alt="YouTube Icon" />
                    </ExternalLink>
                    <ExternalLink href="https://www.instagram.com/mcdonaldsuk/">
                        <img src={instagram} alt="Instagram Icon" />
                    </ExternalLink>
                </div>
                <div className='footer-logo'>
                    <Link to='/'>
                        <img src={mcdonaldslogo} alt="McDonald's Logo" />
                    </Link>
                </div>
                <div className='footer-links'>
                    <ExternalLink href="https://www.mcdonalds.com/gb/en-gb/terms-and-conditions.html">
                        Terms &amp; conditions
                    </ExternalLink>
                    <ExternalLink href="https://www.mcdonalds.com/gb/en-gb/cookie-policy.html">
                        Cookie policy
                    </ExternalLink>
                    <ExternalLink href="https://www.mcdonalds.com/gb/en-gb/privacy-policy.html">
                        Privacy policy
                    </ExternalLink>
                </div>
                <div className='footer-copyright'>
                    &copy; 2017 - 2021 McDonald's. All Rights Reserved.
                </div>
                <button id="ot-sdk-btn" className="btn btn--primary ot-sdk-show-settings">Cookie Settings</button>
            </div>
        </footer>
    )
}

export default Footer;