import Hero from "../components/Hero";
import BlocksModal from "../components/BlocksModal";

import greatfood from '../animations/GreatFood.mp4';
import deliveringchoices from '../animations/DeliveringMoreChoices.mp4';
import sustainablesourcing from '../animations/SustainableSourcing.mp4';

const doubleData = [
    {
        id: 1,
        image: sustainablesourcing,
        title: ['Source ', <strong className='red'>quality ingredients</strong>, ' produced by ', <strong className='yellow'>progressive farmers</strong>],
        text: "",
        smalltitle: "Sustainable sourcing",
        fliptext: "We will support resilient livelihoods and sustainable outcomes through McDonald’s Farm Forward, benefiting over 23,000 British and Irish farmers.",
        showar: true
    },
    {
        id: 2,
        image: deliveringchoices,
        title: ['We will lead by helping customers make more ', <strong>balanced choices</strong>],
        text: "",
        smalltitle: "Delivering more choice",
        fliptext: "We will launch the McDonald’s Nutrition Innovation Council, to create recipe reformulations and innovations which support more balanced options that taste delicious.",
        yellow: true,
        showar: true
    }
];

function GreatFood () {
    return (
        <div>
            <Hero 
                title={['Great ', <strong className='yellow'>Food</strong>]}
                text="We believe in great food – delicious meals and drinks, made from quality, sustainably sourced ingredients, and supporting UK and Irish farmers."
                image={greatfood}   
                key="1"
            />
            <section className='half-grey-bg'>
                <div className='container block-container'>
                    <div className='row'>
                        {
                            doubleData.map(data => 
                                <BlocksModal 
                                    key={data.id}
                                    id={data.id} 
                                    image={data.image}
                                    title={data.title}
                                    text={data.text} 
                                    cols="col-12 col-lg-6 position-relative"   
                                    smalltitle={data.smalltitle}
                                    fliptext={data.fliptext}
                                    yellow={data.yellow}
                                    showar={data.showar}
                                />
                            )
                        }
                    </div>
                </div>
            </section>
        </div>
    )
}

export default GreatFood;