import Blocks from "../components/Blocks";
import LargeHero from "../components/LargeHero";
import TextBlock from "../components/TextBlock";

import planetpositive from '../animations/PlanetPositive.mp4';
import greatfood from '../animations/GreatFood.mp4';
import greatrestaurants from '../animations/GreatRestaurants.mp4';
import peoplepositive from '../animations/PeoplePositive.mp4';
import servingcommunities from '../animations/ServingOurCommunities.mp4';

const blockData = [
    {
        id: 1,
        image: planetpositive,
        title: ['Planet ', <strong className='yellow'>Positive</strong>],
        text: "Taking action on climate and nature",
        link: "/planet-positive"
    },
    {
        id: 2,
        image: greatfood,
        title: ['Great ', <strong className='yellow'>Food</strong>],
        text: "Working on sustainable sourcing and more balanced options",
        link: "/great-food"
    },
    {
        id: 3,
        image: greatrestaurants,
        title: ['Great ', <strong className='yellow'>Restaurants</strong>],
        titletwo: "Restaurants",
        text: "Net zero and closing the loop on waste",
        link: "/great-restaurants"
    },
    {
        id: 4,
        image: peoplepositive,
        title: ['People ', <strong className='yellow'>Positive</strong>],
        text: "Building skills, jobs and opportunities",
        link: "/people-positive"
    },
    {
        id: 5,
        image: servingcommunities,
        title: ['Serving our ', <strong className='yellow'>Communities</strong>],
        text: "Stepping up for families and young people",
        link: "/serving-our-communities"
    },
    {
        id: 6,
        image: "",
        title: ['Our ', <strong>long-term goals</strong>, " in full"],
        //text: "Our long-term goals in full",
        text: "",
        link: "/a-plan-for-change",
        yellow: true
    }
];

function Home () {
    return (
    <div>
       <LargeHero 
       key={1}
        title={['Change ' , <br className='hide'/>, 'a little,', <br/>, 'change ',  <br className='hide'/>, 'a lot', <sup>&trade;</sup>]}
       />
       <TextBlock />
       <section className='grey-bg'>
            <div className='container'>
                <h2 className='title'>How we're <strong className='yellow'>changing</strong></h2>
                <div className='row'>
                    {
                        blockData.map(data => 
                            <Blocks 
                                key={data.id}
                                id={data.id} 
                                image={data.image}
                                title={data.title}
                                text={data.text} 
                                cols="block--margin col-12 col-lg-4"   
                                link={data.link}
                                yellow={data.yellow}
                            />
                        )
                    }
                </div>
            </div>
       </section>
    </div>

    )
}

export default Home;