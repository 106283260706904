import Hero from "../components/Hero";
import BlocksModal from "../components/BlocksModal";

import planetpositive from '../animations/PlanetPositive.mp4';
import climatepositive from '../animations/ClimatePositive.mp4';
import naturepositive from '../animations/NaturePositive.mp4';

const doubleData = [
    {
        id: 1,
        image: climatepositive,
        title: '',
        text: "",
        link: "/people-positive",
        smalltitle: "Climate Positive",
        fliptext: "We will aim to reach net zero emissions by 2040 across our entire business and value chain to have a positive impact on climate.",
        yellowodal: true,
        largeishtitle: true,
        showar: true
    },
    {
        id: 2,
        image: naturepositive,
        title: ['We’re switching to ', <strong className='yellow'>deforestation-free</strong>,  ' soy in our ingredients and animal feed'],
        text: "",
        link: "/serving-our-communities",
        smalltitle: "Nature Positive",
        fliptext: "We will lead the way in championing sustainable practices and harnessing innovation, to protect forests and promote soil health, biodiversity and water management to have a positive impact on nature.",
        showar: true
    }
];

function PlanetPositive () {
    return (
        <div>
            <Hero 
                title={['Planet ', <strong className='yellow'>Positive</strong>]}
                text='We believe in making a positive difference to the planet, reaching net zero emissions and being nature positive by protecting and restoring nature.'
                image={planetpositive}   
                key="1"
            />
            <section className='half-grey-bg'>
                <div className='container block-container'>
                    <div className='row'>
                        {
                            doubleData.map(data => 
                                <BlocksModal 
                                    key={data.id}
                                    id={data.id} 
                                    image={data.image}
                                    title={data.title}
                                    text={data.text} 
                                    cols="col-12 col-lg-6 position-relative"   
                                    link={data.link}
                                    smalltitle={data.smalltitle}
                                    fliptext={data.fliptext}
                                    yellowmodal={data.yellowodal}
                                    largeishtitle={data.largeishtitle}
                                    showar={data.showar}
                                />
                            )
                        }
                    </div>
                </div>
            </section>
            <section className='grey-bg pt-0'>
                <div className='container'>
                    <div className='yellow-text-box'>
                        <h3>Our Farm Forward programme</h3>
                        <p>We&rsquo;ve been working with farmers for over 20 years. In 2012, we created Farm Forward after consulting with over 1,500 farmers. </p>
                        <p>It&rsquo;s our agriculture programme with <strong>three big aims</strong>: resilient farmers, raising animal welfare standards and making environmental improvements.</p>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default PlanetPositive;