import { Link } from 'react-router-dom';

import arrowlink from './assets/link-arrow.svg';
import darkarrowlink from './assets/link-arrow--dark.svg';

import './scss/Blocks.scss';

function Blocks (props) {
    return (
        <div className={props.cols} key={props.id}>
            <Link to={props.link} className={`block ${props.image === "" ? "justify-content-center " : ""} ${props.yellow ? "yellow-bg " : ""} `}>   
                {
                   props.image !== "" &&
                    // <img src={props.image} alt={props.title} />
                    <video autoPlay muted loop playsInline>
                        <source src={props.image} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                }
                <h3>{props.title}</h3>
                <p>{props.text}</p>
                <img src={props.yellow ? darkarrowlink : arrowlink} className='arrow-link' alt={props.title} />
            </Link>
        </div>
    ) 
}
export default Blocks;