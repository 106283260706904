import { Link } from 'react-router-dom';

import './scss/Hero.scss';

import mcdonaldslogo from './layout/assets/mcdonalds-logo.svg'

function Hero (props) {

    return (
        <section className='hero'>
            <div className='container'>
                <div className='row'>
                    <div className='col'>
                        <Link to='/'>
                            <img src={mcdonaldslogo} alt="McDonald's Logo" className='logo' />
                        </Link>                        
                        <h1>
                            {props.title}
                        </h1>
                        <p>{props.text}</p>
                    </div>
                    <div className='col'>
                        {/* <img src={props.image} alt={props.title} className='hero__image' /> */}
                        <video autoPlay muted loop playsInline className='hero__image'>
                            <source src={props.image} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Hero;