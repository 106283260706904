import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import { ExternalLink } from "react-external-link";

import facebook from './assets/facebook.svg';
import instagram from './assets/instagram.svg';
import twitter from './assets/twitter.svg';
import youtube from './assets/youtube.svg';

import './scss/Header.scss';

import burgerNav from './assets/nav.svg';
import closeNav from './assets/nav-close.svg';

function Header () {

    const [menuVisible, setmenuVisible] = useState(false); 

    const showMenu = () => {
        setmenuVisible(true);
    } 

    const hideMenu = () => {
        setmenuVisible(false);
    }

    const [headerClassName, setHeaderClassName] = useState('');

    const handleScroll = (headerClassName) => {
        if (headerClassName !== 'is_stuck' && window.pageYOffset >= 10) {
            setHeaderClassName('is_stuck');
        } else if (headerClassName === 'is_stuck' && window.pageYOffset < 10) {
            setHeaderClassName('');
        }
    }

    React.useEffect(() => {
        window.onscroll = () => handleScroll(headerClassName);
    }, [headerClassName]);


    return (
        <div>
            {
                menuVisible &&
                <div className='full-menu'>
                    <header>
                        <div className='container'>
                            <img src={closeNav} alt="Close Nav Icon" onClick={hideMenu} />
                            <ExternalLink href='https://www.mcdonalds.com/gb/en-gb/our-plan-for-change.html' onClick={hideMenu}>
                                McDonald's
                            </ExternalLink>
                        </div>
                    </header>
                    <div className='navigation'>
                        <div className='container'>
                            <nav>
                                <ul>
                                    <li>
                                        <Link to='/' onClick={hideMenu}>Home</Link>
                                    </li>
                                    <li>
                                        <Link to='/planet-positive' onClick={hideMenu}>Planet Positive</Link>
                                    </li>
                                    <li>
                                        <Link to='/great-food' onClick={hideMenu}>Great Food</Link>
                                    </li>
                                    <li>
                                        <Link to='/great-restaurants' onClick={hideMenu}>Great Restaurants</Link>
                                    </li>
                                    <li>
                                        <Link to='/people-positive' onClick={hideMenu}>People Positive</Link>
                                    </li>
                                    <li>
                                        <Link to='/serving-our-communities' onClick={hideMenu}>Serving our Communities</Link>
                                    </li>
                                    <li>
                                        <Link to='/a-plan-for-change' onClick={hideMenu}>Our Plan for Change</Link>
                                    </li>
                                    <li>
                                        <ExternalLink onClick={hideMenu} href="https://interactive.togetherwecanchangealot-mcdonalds.co.uk/">
                                            View restaurant innovations 
                                        </ExternalLink>
                                    </li>
                                </ul>
                            </nav>
                            <div className='header-social-icons'>
                                <ExternalLink href="https://www.facebook.com/McDonaldsUK/">
                                    <img src={facebook} alt="Facebook Icon" />
                                </ExternalLink>
                                <ExternalLink href="https://twitter.com/mcdonaldsuk">
                                    <img src={twitter} alt="Twitter Icon" />
                                </ExternalLink>
                                <ExternalLink href="https://www.youtube.com/user/mcdonaldsuk">
                                    <img src={youtube} alt="YouTube Icon" />
                                </ExternalLink>
                                <ExternalLink href="https://www.instagram.com/mcdonaldsuk/">
                                    <img src={instagram} alt="Instagram Icon" />
                                </ExternalLink>
                            </div>
                        </div>
                    </div>
                </div>
            }

            <header id="header_menu" className={headerClassName}>
                <div className='container'>
                    <img src={burgerNav} alt="Nav Icon" onClick={showMenu} />
                    <ExternalLink href='https://www.mcdonalds.com/gb/en-gb/our-plan-for-change.html' onClick={hideMenu}>
                        McDonald's
                    </ExternalLink>
                </div>
            </header>
        </div>

    )
}

export default Header;