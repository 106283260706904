import {Route, Switch} from 'react-router-dom';

import Layout from './components/layout/Layout';

import Home from './views/Home';
import GreatFood from './views/GreatFood';
import GreatRestaurants from './views/GreatRestaurants';
import PeoplePositive from './views/PeoplePositive';
import PlanetPositive from './views/PlanetPositive';
import PlanForChange from './views/PlanForChange';
import ServingOurCommunities from './views/ServingOurCommunities';

import './App.scss'

function App() {
  return (
      <Layout>
        <Switch>
          <Route path='/' exact >
            <Home />
          </Route>
          <Route path='/planet-positive' exact>
            <PlanetPositive />
          </Route>
          <Route path='/great-food' exact>
            <GreatFood />
          </Route>
          <Route path='/great-restaurants' exact>
            <GreatRestaurants />
          </Route>
          <Route path='/people-positive' exact>
            <PeoplePositive />
          </Route>
          <Route path='/serving-our-communities' exact>
            <ServingOurCommunities />
          </Route>
          <Route path='/a-plan-for-change' exact>
            <PlanForChange />
          </Route>
        </Switch>
      </Layout>
  )
}

export default App;