import { Link } from 'react-router-dom';

import './scss/Hero.scss';

import mcdonaldslogo from './layout/assets/mcdonalds-logo.svg';
import arrow from './assets/down-arrow.svg';

function LargeHero (props) {

    const key = 0;

    return (
        <section className={`hero hero--large hero--grey ${props.nomobilebg ? "hero--large-no-bg " : ""} `} key={key}>
            <div className='container'>
                <Link to='/'>
                    <img src={mcdonaldslogo} alt="McDonald's Logo" className='logo' />
                </Link> 
                <h1>{props.title}</h1>
                {
                    props.text &&
                    props.text
                }
            </div>
            <img src={arrow} className='down-arrow' alt='Please scroll down.' title='Please scroll down'/>
        </section>
    )
}

export default LargeHero;