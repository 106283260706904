import Hero from "../components/Hero";
import BlocksModal from "../components/BlocksModal";

import greatrestaurants from '../animations/GreatRestaurants.mp4';
import netzero from '../animations/NetZero.mp4';
import closingtheloop from '../animations/ClosingTheLoopOnWaste.mp4';

const doubleData = [
    {
        id: 1,
        image: netzero,
        title: '',
        text: "",
        smalltitle: "Net zero standard restaurants",
        fliptext: "We will aim to transition all our restaurants to a UK industry net zero emissions standard from construction to use.",
        showar: true
    },
    {
        id: 2,
        image: closingtheloop,
        title: ['We’ll make ', <strong className='yellow'>changes</strong>, ' to recycle, reuse or compost our waste'],
        text: "",
        smalltitle: "Closing the loop on waste",
        fliptext: "We will create a circular system where our packaging and waste has a second life.",
        yellowmodal: true,
        showar: true
    }
];

function GreatRestaurants () {
    return (
        <div>
            <Hero 
                title={['Great ', <strong className='yellow'>Restaurants</strong>]}
                text="We believe great food deserves to be served in great restaurants which are sustainable for the future – net zero and closing the loop on waste."
                image={greatrestaurants}   
                key="1"
            />
            <section className='half-grey-bg'>
                <div className='container block-container'>
                    <div className='row'>
                        {
                            doubleData.map(data => 
                                <BlocksModal 
                                    key={data.id}
                                    id={data.id} 
                                    image={data.image}
                                    title={data.title}
                                    text={data.text} 
                                    cols="col-12 col-lg-6 position-relative"   
                                    smalltitle={data.smalltitle}
                                    fliptext={data.fliptext}
                                    yellow={data.yellow}
                                    yellowmodal={data.yellowmodal}
                                    showar={data.showar}
                                />
                            )
                        }
                    </div>
                </div>
            </section>
        </div>
    )
}

export default GreatRestaurants;