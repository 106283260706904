import React, { useEffect, useRef, useState } from 'react';
import { ExternalLink } from 'react-external-link';

import { HashLink } from 'react-router-hash-link';

import './scss/Info.scss';

function Info (props) {

    let getAnchorURL = (id) => {
        return '/a-plan-for-change#' + id;
    }

    const [isSticky, setSticky] = useState(false);
    const ref = useRef(null);
    const handleScroll = () => {
      if (ref.current) {
        setSticky(ref.current.getBoundingClientRect().top <= 0);
      }
    };
  
    useEffect(() => {
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', () => handleScroll);
      };
    }, []);

    return (
        <div>
            <div id="info_scroll" className={`info-nav grey-bg${isSticky ? ' is_stuck' : ''}`} ref={ref}>
                <div className='container'>
                    <div className='info-nav__title'>Jump to</div>
                    <div className='info-nav__nav'>
                        {
                            props.info.map(link =>
                                <HashLink smooth to={getAnchorURL(link.id)} key={link.id}>
                                    {link.title}
                                </HashLink>
                            )
                        }
                    </div>
                </div>
            </div>

            <section className='info grey-bg'>
                <div className='container' >
                    {
                        props.info.map(data =>
                            <div className='info-container' id={data.id} key={data.id}>
                                <h2>{data.title}</h2>   

                                <div className='info-section'>

                                    <div className='info-block-container'>
                                        {
                                            data.blocks.map(block =>
                                                <div className='info-block'>
                                                    <h4>{block.title}</h4>
                                                    <div className='info-commitment'>                        
                                                        <strong>Our commitment</strong>                        
                                                        <div className='info-commitment__commitment'>
                                                            {block.text}
                                                        </div>                        
                                                    </div>
                                                    <strong>Our goals</strong>
                                                    {
                                                        block.goals.map(goal =>
                                                        <div className='info-goals__goal'>
                                                            <strong>{goal.title}</strong><br/>
                                                            {goal.text}
                                                        </div>
                                                        )
                                                    }
                                                </div>
                                            )
                                        }
                                    </div>

                                    {
                                        data.id === 1 &&

                                        <div className='info-footnote'>
                                            * Product scope includes soy sourced for feed of livestock used in McDonald's products. It also includes soy used directly as an ingredient in McDonald's products. 
                                        </div>
                                    }   

                                    {
                                        data.id === 3 &&

                                        <div className='info-footnote'>
                                            *Annual reduction in material compared to 2020.
                                        </div>
                                    }                                    

                                </div>                                

                            </div>
                            
                        )
                    }

                    <div className='text-center'>
                        <ExternalLink href='https://www.mcdonalds.com/gb/en-gb/our-plan-for-change.html' className='btn btn--primary'>
                            Learn more
                        </ExternalLink>
                    </div>

                </div>
            </section>

        </div>

        
       
    )
}

export default Info;