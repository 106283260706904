import Hero from "../components/Hero";
import BlocksModal from "../components/BlocksModal";

import peoplepositive from '../animations/PeoplePositive.mp4';

const doubleData = [
    {
        id: 1,
        image: "",
        title: [<strong className='yellow'>By 2030</strong>, ' help one million people gain new skills and open doors to jobs'],
        text: "",
        smalltitle: "Opening doors to jobs",
        fliptext: "We will help our communities gain new skills and open doors through training, work experience and equal employment opportunities.",
        showar: true
    },
    {
        id: 2,
        image: "",
        title: "",
        text: "We are supporting our local communities by providing a youth worker in every restaurant, making a difference to young people by giving them confidence and skills. Our careers work in schools also opens up possibilities for pupils. We have always sought to help people access opportunities and make the most of their potential. We’ve done that by providing flexible working and training. Our new Plan for Change means we will do even more.",
        smalltitle: "Giving young people confidence",
        fliptext: "We will create a circular system where our packaging and waste has a second life.",
        yellow: true,
        noflip: true
    },
    {
        id: 3,
        image: "",
        title: ['We ', <strong className='red'>champion individuality</strong>, ' and fair treatment in', <span>&nbsp;</span>,'access, ', <strong className='yellow'>opportunity and advancement for all</strong>],
        text: "",
        smalltitle: "Inclusive and diverse",
        fliptext: "From our senior leaders to our restaurant teams, we will reflect the diverse communities we are part of and stand up for individuality and equality.",
        //nolarge: true,
        notitlepadding: true,
        showar: true
    }
];

function PeoplePositive () {
    return (
        <div>
            <Hero 
                title={['People ', <strong className='yellow'>Positive</strong>]}
                text="We are a people business, and we believe in being people positive. We’ve always been proud of giving people a start in their careers and supporting them to get ahead."
                image={peoplepositive}   
                key="1"
            />
            <section className='half-grey-bg'>
                <div className='container block-container'>
                    <div className='row'>
                        {
                            doubleData.map(data => 
                                <BlocksModal 
                                    key={data.id}
                                    id={data.id} 
                                    image={data.image}
                                    title={data.title}
                                    text={data.text} 
                                    cols="col-12 col-lg-4 position-relative"   
                                    smalltitle={data.smalltitle}
                                    fliptext={data.fliptext}
                                    yellow={data.yellow}
                                    yellowmodal={data.yellowmodal}
                                    noflip={data.noflip}
                                    nolarge={data.nolarge}
                                    notitlepadding={data.notitlepadding}
                                    showar={data.showar}
                                />
                            )
                        }
                    </div>
                </div>
            </section>
        </div>
    )
}

export default PeoplePositive;