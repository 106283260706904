import Hero from "../components/Hero";
import BlocksModal from "../components/BlocksModal";

import servingcommunities from '../animations/ServingOurCommunities.mp4';
import foodredistribution from '../animations/FoodRedistribution_YELLOW.mp4';

const doubleData = [
    {
        id: 1,
        image: "",
        title: [<strong className='red'>Home</strong>,' away from ', <strong className='yellow'>home</strong>],
        titletwo: "",
        text: "",
        smalltitle: "Ronald McDonald House Charities® UK",
        fliptext: "The Ronald McDonald House Charities® (RMHC®) UK enables families to stay together near specialist children’s hospitals when a child is diagnosed with a life-threatening illness.",
        yellowmodal: true,
        largetitle: true,
        largepadding: true,
        buttontext: "Find out more",
        buttonlink: "https://www.mcdonalds.com/gb/en-gb/good-to-know/ronald-mcdonald-house-charities.html",
        nopadding: true
    },
    {
        id: 2,
        image: foodredistribution,
        title: "",
        titletwo: "",
        text: "",
        smalltitle: "Food redistribution",
        fliptext: "As we emerge from the pandemic we will continue to support those in greatest need in our communities, funding the redistribution of food through partnerships with FareShare, Community Shop and Irish food waste charity FoodCloud. This ensures we get vital supplies to those in need from our distribution centres while ensuring precious resources don’t go to waste. We have also launched our new offer for NHS workers who can get 20% off when ordering through the My McDonald’s App.",
        yellow: true,
        largepadding: true,
        buttontext: "Watch our video",
        buttonlink: "https://www.youtube.com/watch?v=0XshEG_GeJQ"
    }
];

function ServingOurCommunities () {
    return (
        <div>
            <Hero 
                title={['Serving our ', <strong className='yellow'>Communities</strong>]}
                text="With around 1,400 restaurants across the UK and Ireland – and each one rooted in its local community – little changes add up to a lot."
                image={servingcommunities}   
                key="1"
            />
            <section className='half-grey-bg'>
                <div className='container block-container'>
                    <div className='row'>
                        {
                            doubleData.map(data => 
                                <BlocksModal 
                                    key={data.id}
                                    id={data.id} 
                                    image={data.image}
                                    title={data.title}
                                    titletwo={data.titletwo}
                                    text={data.text} 
                                    cols="col-12 col-lg-6 position-relative"   
                                    smalltitle={data.smalltitle}
                                    fliptext={data.fliptext}
                                    yellow={data.yellow}
                                    yellowmodal={data.yellowmodal}
                                    largetitle={data.largetitle}
                                    largepadding={data.largepadding}
                                    buttontext={data.buttontext}
                                    buttonlink={data.buttonlink}
                                    notitlepadding={data.nopadding}
                                />
                            )
                        }
                    </div>
                </div>
            </section>
        </div>
    )
}

export default ServingOurCommunities;