import { useEffect, useState } from "react";

import uparrow from './assets/up-arrow.svg';

const GoTop = () => {

    // The back-to-top button is hidden at the beginning
    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
    window.addEventListener("scroll", () => {
        if (window.pageYOffset > 300) {
        setShowButton(true);
        } else {
        setShowButton(false);
        }
    });
    }, []);

    // This function will scroll the window to the top 
    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    return (
        <div>
            {showButton && 
            <img src={uparrow} className='back-top' onClick={scrollToTop} alt='Back to top' title='Back to top' />
            }
        </div>      

    )

    
  };
 export default GoTop;