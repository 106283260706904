import { Link } from 'react-router-dom';
import { ExternalLink } from 'react-external-link';

import './scss/TextBlock.scss'

function TextBlock () {
    return (
        <section className='text-block'>
            <div className='container'>
                <h2>
                 We're regularly making small <strong className='yellow'>changes</strong> that add up to something much <strong className='yellow'>bigger</strong>.
                </h2>
                <p>We’ve set ourselves the challenge to lead this change across four key areas. Our aims include achieving <strong>net zero emissions</strong> across our UK and Ireland businesses by 2040.</p>
                <p>View our <strong>Plan for Change</strong> with goals and actions to help us lead positive change.</p>
                <Link to='/a-plan-for-change' className='btn btn--primary'>
                    Our Plan for Change
                </Link>
                <ExternalLink href='https://interactive.togetherwecanchangealot-mcdonalds.co.uk/' className='btn btn--secondary'>
                    View our restaurant innovations
                </ExternalLink>
            </div>
        </section>
    )
}

export default TextBlock;