import { useState } from 'react';
import { Link } from 'react-router-dom';
import { ExternalLink } from 'react-external-link';

import openmodal from './assets/open-modal.svg';
import darkopenmodal from './assets/open-modal--dark.svg';
import closemodal from './assets/close-modal.svg';
import darkclosemodal from './assets/close-modal--dark.svg';
import aricon from './assets/ar-icon.svg';
import ariconlight from './assets/ar-icon--white.svg';

import './scss/Blocks.scss';

function BlocksModal (props) {

    const [flipVisible, setSflipVisible] = useState(false);

    function showFlip () {
        setSflipVisible(true);
    }

    function hideFlip () {
        setSflipVisible(false);
    }


    return (
        <div className={props.cols}>

            <div className={`block ${props.largepadding ? "large-padding " : ""} ${props.image === "" || props.image !== "" && props.title === "" && props.text === "" ? "justify-content-center " : ""} ${props.yellow ? "yellow-bg " : ""} ${props.noflip ? "block--no-flip " : ""}`} onClick={props.noflip ? undefined : showFlip}>   

                {
                    props.smalltitle && 
                    <span className='small-title'>{props.smalltitle}</span>
                }
                
                {
                    props.image !== "" && 
                    //<img src={props.image} alt={props.title} /> 
                    <video autoPlay muted loop playsInline className={props.title === "" && props.text === "" ? "larger " : ""}>
                        <source src={props.image} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                }

                {
                    props.image !== "" && props.title !== "" &&                 
                    <h3 className={`${props.notitlepadding ? "" : "no-padding"}`}>{props.title}</h3>                     
                }

                { 
                    props.image === "" && props.title !== "" &&
                    <h3 className={`${props.nolarge ? "" : "large"} ${props.largeishtitle ? "large-ish" : ""} ${props.largetitle ? "larger" : ""} ${props.notitlepadding ? "no-padding" : ""}`}>{props.title}</h3>
                }
                
                { 
                    props.text !== "" &&
                    <p>{props.text}</p> 
                }                

                {
                    !props.noflip &&
                    <img src={props.yellow ? darkopenmodal : openmodal} className='arrow-link' alt={props.title} />
                }
                
            </div>
            

            {
                flipVisible && 
                <div className={`block block--flip justify-content-center ${props.yellowmodal ? "yellow-bg" : null}`} onClick={hideFlip}>   
                    
                    {props.smalltitle && <span className='small-title'>{props.smalltitle}</span>}

                    <p>{props.fliptext}</p>

                    <div>
                        {
                            props.buttontext &&

                            <ExternalLink href={props.buttonlink} className={`btn  ${props.yellowmodal ? "btn--secondary" : "btn--primary"}`}>
                                {props.buttontext}
                            </ExternalLink>
                            
                        }
                        
                        {
                            !props.buttontext &&

                            <Link to="/a-plan-for-change" className={`btn  ${props.yellowmodal ? "btn--secondary" : "btn--primary"}`}>
                                Our Plan for Change
                            </Link>
                        }

                        {
                            props.showar && 

                            <div className='block-ar'>
                                <div>Interact with all our innovations.</div>
                                <ExternalLink href="https://interactive.togetherwecanchangealot-mcdonalds.co.uk/" className={`btn  ${props.yellowmodal ? "btn--secondary" : "btn--primary"}`}>
                                    <img src={props.yellowmodal ? ariconlight : aricon} alt="Bring to life" />
                                    <span>Bring to life</span>
                                </ExternalLink>
                            </div>
                        }
                        
                    </div>

                    <img src={props.yellowmodal ? darkclosemodal : closemodal} class="arrow-link" alt={props.title} />

                </div>
            }                   

        </div>
    ) 
}

export default BlocksModal;
