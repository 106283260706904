import LargeHero from "../components/LargeHero";
import Info from "../components/Info";
import GoTop from "../components/GoTop";


const info = [
    {
        id: 1,
        url: "/a-plan-for-change#1",
        title: ['Planet ', <strong className='yellow'>Positive</strong>],
        blocks: [
            {
                title: "Climate positive",
                text: "We will aim to reach net zero emissions by 2040 across our entire business and value chain to have a positive impact on climate.",
                goals: [
                     { 
                        title: "By 2030",
                        text: "Achieve net zero emissions for all our restaurants and offices."
                    },
                    {
                        title: "By 2040",
                        text: "Achieve net zero emissions for our entire business and value chain, working with our suppliers and franchisees to have a positive impact on climate."
                    }
                ]    
            },
            {
                title: "Nature positive",
                text: "We will lead the way in championing sustainable practices and harnessing innovation, to protect forests and promote soil health, biodiversity and water management to have a positive impact on nature.",
                goals: [
                    { 
                        title: "By 2023",
                        text: "Launch our nature positive programme to scale innovative nature-based solutions."
                    },
                    {
                        title: "By 2025",
                        text: "Have regenerative agriculture initiatives in each of our priority supply chains to promote soil health, water management and biodiversity."
                    },
                    { 
                        title: "By 2026",
                        text: "Soy used in our ingredients and in the animal feed in our supply chain will be deforestation-free.*"
                    },
                    {
                        title: "By 2030",
                        text: "The global McDonald’s business will have eliminated deforestation from our supply chains."
                    }
                ]
    
            }
        ]
    },
    {
        id: 2,
        title: ['Great ', <strong className='yellow'>Food</strong>],
        blocks: [
            {
                title: "Sustainable sourcing",
                text: "We will source quality ingredients produced by progressive farmers to leading animal welfare and environmental standards.",
                goals: [
                     { 
                        title: "By 2023",
                        text: "Develop a Plan for Change Scorecard in collaboration with independent experts, to expand and embed ethical and sustainability criteria in our sourcing decisions."
                    },
                    {
                        title: "By 2025",
                        text: [
                            "Support resilient livelihoods and sustainable outcomes through McDonald’s Farm Forward, benefiting over 23,000 British and Irish farmers.",
                            <br />,
                            <br />,
                            "Engage 10 million families, and connect them to farming, nature and the future of sustainable food."
                        ]
                    },
                    {
                        title: "By 2030",
                        text: "Have kick-started over 100 careers in food and farming for young people from diverse backgrounds through our  McDonald’s Young Talent programme."
                    }
                ]   
            },
            {
                title: "Delivering more choice",
                text: "We will lead by helping customers make more balanced choices that don’t compromise on taste, convenience or price.",
                goals: [
                    { 
                        title: "From 2021",
                        text: "Conduct ongoing positive behaviour change research and implement findings across our order channels to inspire our customers to make more balanced choices."
                    },
                    {
                        title: "By 2022",
                        text: 'Launch the McDonald’s Nutrition Innovation Council, to create recipe reformulations and innovations that support more balanced choices that taste delicious.'
                    },
                    { 
                        title: "By 2023",
                        text: "Help customers to navigate our menu by refreshing our menu signposting and nutrition labelling."
                    },
                    {
                        title: "By 2025",
                        text: "Have a market-leading vegan plant-based food and drinks offering."
                    },
                    {
                        title: "By 2026",
                        text: "A minimum of 50% of our products available across the total of our restaurants, excluding promotional products, will meet our nutrition criteria."
                    }
                ]
    
            }
        ]
    },
    {
        id: 3,
        title: ['Great ', <strong className='yellow'>Restaurants</strong>],
        blocks: [
            {
                title: "Net zero standard restaurants",
                text: "We will aim to transition all our restaurants to a UK industry net zero emissions standard from construction to use.",
                goals: [
                     { 
                        title: "From 2022",
                        text: "Launch our first UK industry net zero emissions standard restaurant and create a blueprint for freehold new builds."
                    },
                    {
                        title: "From  2023",
                        text: "Furniture in new and refurbished restaurants will be made with recycled or certified materials and designed to be recycled or reused at end of life."
                    },
                    {
                        title: "By 2030",
                        text: "Achieve net zero emissions for all our restaurants and offices."
                    }
                ]   
            },
            {
                title: "Closing the loop on waste",
                text: "We will create a circular system where our packaging and waste has a second life.",
                goals: [
                    { 
                        title: "By 2024",
                        text: "Our customer packaging will be made from renewable, recycled or certified sources and designed to be recyclable or compostable."
                    },
                    {
                        title: "By 2027",
                        text: "Give the waste collected from our restaurants a second life by recycling, reusing or composting."
                    },
                    { 
                        title: "By 2030",
                        text: "Reduce the amount of material used across packaging in customer and kitchen areas in the McDonald’s system by 20% versus 2020. *"
                    }
                ]
    
            }
        ]
    },
    {
        id: 4,
        title: ['People ', <strong className='yellow'>Positive</strong>],
        blocks: [
            {
                title: "Opening doors to jobs",
                text: "We will help our communities gain new skills and  open doors through training, work experience and equal employment opportunities.",
                goals: [
                     { 
                        title: "By 2024",
                        text: "Have a youth worker in every restaurant."
                    },
                    {
                        title: "By 2025",
                        text: "Have supported 3,000 apprentices."
                    },
                    {
                        title: "By 2030",
                        text: "Help one million people gain new skills and open doors to jobs."
                    }
                ]   
            },
            {
                title: "Inclusive and diverse",
                text: "From our senior leaders to our restaurant teams, we will reflect the diverse communities we are part of and stand up for individuality and equality.",
                goals: [
                    { 
                        title: "By 2024",
                        text: "We will work with our supplier partners and franchisees to advance social inclusion through a Mutual DE&I Commitment."
                    },
                    {
                        title: "By 2025",
                        text: "40% of participants in our new corporate placement and internship programme for our restaurant employees will be from under-represented groups."
                    },
                    { 
                        title: "By 2030",
                        text: [
                            'Reach and maintain gender balance within our senior leadership roles.', 
                            <br />,
                            <br />, 
                            'Ensure 40% of all senior leadership roles are held by people from under-represented groups.']
                    }
                ]
    
            }
        ]
    }
]

function PlanForChange () {
    return (
    <div>
       <LargeHero 
            title={['A ', <span className='yellow'>Plan</span>, ' for ', <span className='yellow'>change</span>]}
            text={[
                <p>Our <strong>Plan For Change</strong> is a clear plan with goals and actions to help us lead positive change right across our business.</p>,
                <p>We’re challenging ourselves to lead change across four key areas: Planet, People, Restaurants and Food. </p>,
                <p><strong>We’ve set out goals in each of our focus areas to help guide our actions.</strong></p>,
            ]}
            nomobilebg
       />
       <Info 
            info={info}                    
        />        
        <GoTop />
    </div>

    )
}

export default PlanForChange;